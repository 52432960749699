<template>
  <div class="heatmap2">

    <div class="left">
      <b style="margin-bottom:12px; display:inline-block;">
        {{item ? `${recordings.length} recordings for ${item.title ? item.title : 'video with no title'}` : 'Select a video to view heatmap recordings'}}
      </b>
      <div class="videolist">
        <div v-for="(video, index) in videos" :key="video.id" @click="item = video" :class="{selected: item && item.id == video.id}">
          <span class="index">{{index + 1}}</span>
          <span class="title">{{video.title ? video.title : 'no title'}}</span>
        </div>
      </div>      
      <div class="heatmapcontainer" v-show="item">
        <media-controller class="mediacontroller" :style="{width: videoWidth+'px', height: videoHeight+60+'px'}">
        <!-- <media-controller class="mediacontroller" > -->
          <video slot="media" ref="video" :key="videoSource" :src="videoSource" v-show="item" :width="videoWidth" id="video" @loadedmetadata="videoMetadataLoaded">
            <!-- <source src="../assets/demovideo2.mp4" type="video/mp4"> -->
            <!-- <source src="../assets/BC_LR_01.mp4" type="video/mp4"> -->
            <!-- <source src="https://s3.eu-central-1.amazonaws.com/dev.public.avris.io/balint-test1/BC_LR_01.mp4" type="video/mp4"> -->
            Sorry, your browser doesn't support embedded videos.
          </video>
          <media-control-bar>
            <media-play-button></media-play-button>
            <media-mute-button></media-mute-button>
            <!-- <media-volume-range></media-volume-range>  -->
            <media-time-range ref="mediatimerange"></media-time-range> 
            <media-seek-backward-button/>
            <media-seek-forward-button/>
            <media-time-display show-duration></media-time-display>    
          </media-control-bar>
        </media-controller>

        <div class="heatmap" ref="heatmap" :style="{width: videoWidth+'px', height: videoHeight+'px', visibility: drawHeatmap ? 'visible' : 'hidden'}">      
        </div>

        <canvas id="motionTrailCanvas" ref="motionTrailCanvas" :width="videoWidth" :height="videoHeight" />

        <div class="areas" ref="areas" :style="{width: videoWidth+'px', height: videoHeight+'px', visibility: !hideAreas ? 'visible' : 'hidden'}">
          <vue-draggable-resizable v-for="(area, index) in areas" :key="area.label + resizeChangestamp" v-show="area.checked"
            class="area" 
            :w="area.w" 
            :h="area.h" 
            :x="area.x" 
            :y="area.y" 
            @dragging="onAreaDrag" 
            @resizing="onAreaResize" 
            @resizestop="recalcArea"
            @dragstop="recalcArea"
            :parent="true"
            :data-areaindex="index"
          >
            <span class="label" :style="{width: Math.round(area.w - 4)+'px', backgroundColor: area.color}" :class="getAreaLabelClass(area)">{{area.label}}</span>        
            <span v-if="labelPercentages" class="label">{{area.liveCoverage ? area.liveCoverage.toFixed(1) : '-'}}%</span>  
            <br v-if="labelPercentagesOverall">      
            <span v-if="labelPercentagesOverall" class="label">Overall {{area.aggregatedCoverage ? area.aggregatedCoverage.toFixed(1) : '-'}}%</span>        
          </vue-draggable-resizable>
        </div>

        <div class="sections" ref="sections" v-if="sections || markers">
          <div v-for="section in sections" class="section" :key="section.title" :style="{width: section.width+'%', left: section.left+'%'}">
            {{section.title}}
          </div>
          <div v-for="marker in markers" class="marker" :key="marker.title" :style="{left: marker.left+'%'}" @click="$refs.video.currentTime = marker.at-2">
            <span>{{marker.title}}</span>
          </div>
        </div>
      </div>
      <div v-if="!item" class="video-placeholder">        
      </div>
    </div>

    <div style="height: 100%; width: 100%; background-color: #eef2f5;"></div>

    <div v-show="!item" style="text-align:center;font-size:20px; padding-top:150px;">
      <span>Choose a video</span>
    </div>
    <div class="right" v-show="item">

      <button class="icon advanced" @click="advancedSettings = !advancedSettings">
        <img v-if="advancedSettings" src="../assets/close-icon.svg" />
        <img v-else src="../assets/gear.svg" />
      </button>

      <div class="settings" :class="{advanced: advancedSettings}" >
        <div>
          <b>Show:</b>
          <br>
          <input type="checkbox" @change="saveSettings" v-model="drawHeatmap"> <span>Heatmap</span>
          <input type="checkbox" @change="saveSettings" v-model="drawGazePoints" style="margin-left:15px;"> <span>Gaze points</span>
          <input type="checkbox" @change="saveSettings" v-model="drawMotionTrail" style="margin-left:15px;"> <span>Motion trail</span>
        </div>
        
        <div v-show="advancedSettings">
          <br>
          <b>Advanced parameters:</b>
          <br>
          <br>
          Sample rate: 
          <input type="number" @change="saveSettings" style="width:70px;" v-model="sampleRate" />
          <br>
          Sliding window samples: <input type="number" @change="saveSettings" style="width:70px;" v-model.number="slidingWindowSamples" /> ({{Math.round(slidingWindowSamples/sampleRate * 1e3) / 1e3}} seconds)
          <br>
          <br>
          Heatmap data scale
          <br>
          Min: <input type="number" @change="saveSettings" style="width: 70px;" v-model.number="dataMin" @input="setScale" />
          Max: <input type="number" @change="saveSettings" style="width: 70px;" v-model.number="dataMax" @input="setScale" />
          Radius: <input type="number" @change="saveSettings" style="width:70px;" v-model.number="radius" />
          <br>
          <br>
          Motion trail
          <br>
          Length: <input type="number" @change="saveSettings" style="width:70px;" v-model.number="motionTrailLength" />    
          Radius: <input type="number" @change="saveSettings" style="width:70px;" v-model.number="motionTrailRadius" />
          <br>
          <button style="margin-top:15px;" class="white" @click="resetSettings">Reset to default</button>

        </div>
      </div>

      <div class="arealist" v-show="!advancedSettings">

        <table style="width:100%;">
          <tr>
            <td colspan="4" style="text-align:left;"><b>Areas:</b></td>
            <td>Current</td>
            <td>Overall</td>
            <td></td>
          </tr>
          <tr v-for="(area, index) in areas" :key="index">
            <td style="width: 20px;">
              <input type="checkbox" v-model="area.checked">
            </td>
            <td style="width: 20px;">
              <div class="color" :style="{backgroundColor: area.color}"></div>  
            </td>  
            <td style="width: 100px;">
              <input type="text" v-model.trim="area.label" @blur="saveAreas">    
            </td>
            <td>
              <div class="chart">                
                <div class="overall" :style="{transform: `scaleX(${(area.aggregatedCoverage/100.0).toFixed(1)})`}"></div>
                <div class="current" :style="{transform: `translateX(${Math.round(area.liveCoverage)}cqw)`}"></div>
              </div>
            </td>
            <td style="width: 60px; text-align:center;">
              {{area.liveCoverage ? area.liveCoverage.toFixed(1) : '-'}}%
            </td>
            <td style="width: 60px; text-align:center;"> 
              {{area.aggregatedCoverage ? area.aggregatedCoverage.toFixed(1) : '-'}}%
            </td>
            <td style="width: 25px;">
              <button class="icon" @click="removeArea(index)" style="width: 20px; height: 20px;">
                <img src="../assets/delete.svg" style="width: 20px; height: 20px;" />
              </button>
            </td>
          </tr>
        </table>
        
        <button class="white left" style="height:35px; margin-top:10px;" @click="addArea" :disabled="loading"><img style="width:20px; height:20px; vertical-align:middle;" src="../assets/add.svg" /> <span style="vertical-align:middle;">Add area</span></button>                  
      </div>

      <div class="buttons">
        Overall report
        <button class="icon" @click="areasToClipboard(false)" :disabled="!areas || !areas.length || loading">
          <img src="../assets/copy.svg" width="20" height="20" />
        </button>
        <button class="icon" @click="areasToClipboard(true)" :disabled="!areas || !areas.length || loading" style="margin-right:40px;">
          <img src="../assets/csv.svg" width="20" height="20" />
        </button>

        Per user report
        <button class="icon" @click="areasPerUserToClipboard(false)" :disabled="!areas || !areas.length || loading">
          <img src="../assets/copy.svg" width="20" height="20" />
        </button>
        <button class="icon" @click="areasPerUserToClipboard(true)" :disabled="!areas || !areas.length || loading">
          <img src="../assets/csv.svg" width="20" height="20" />
        </button>
      </div>
      <div v-if="$store.getters.isAdmin">segmentId: {{segmentId}}, itemId {{item ? item.id : '???'}}</div>  
      <button v-if="$store.getters.isAdmin" @click="areasJsonToClipboard" style="height: 30px; width: 280px;">Copy areas json (for experience)</button>  

<!--       
      <button class="insead white autowidth" style="margin-left: 10px;" :disabled="!recordings || !recordings.length" @click="exportRawDataCsv(false)">Copy CSV</button>
      <button class="insead white autowidth" style="margin-left: 10px;" :disabled="!recordings || !recordings.length" @click="exportRawDataCsv(true)">Export CSV</button> -->
    </div>
    
    <Snackbar ref="snackbar" />
  </div>
</template>



<script>
import h337 from 'heatmap.js'
import VueDraggableResizable from 'vue-draggable-resizable'
import Snackbar from '@/components/Snackbar.vue';
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import 'media-chrome'
import axios from 'axios'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import chroma from 'chroma-js'
import { nextTick } from 'vue'

//let motionPositions = []
let motionPositions = []
let slidingWindowDataPoints = []
let localThis
let sample = 0
let lastSample
let failsafe
let sampleInterval

export default {
  name: 'Heatmap2',
  components: {
    VueDraggableResizable,
    Snackbar
  },
  props: {
    sessionId: Number,
    segmentId: Number,
    videos: Array,
    contentRoot: String
  },
  data: function() {
    return {         
      item: undefined,  
      recordings: [],
      recordingList: [],
      allSelected: true,
      areas :[],
      dataMin: 0,
      dataMax: 10,
      radius: 10,
      scale: 0,
      baseWidth: 200,
      baseHeight: 100,
      videoDuration: undefined,
      heatmapInstance: undefined,
      sampleRate: 10,
      recorderInterval: undefined,
      slidingWindow: true,
      slidingWindowSamples: 10,
      labelPercentages: false,      
      labelPercentagesOverall: false, 
      hideAreas: false,
      showRecordingList: false,
      loading: false,
      areasPerUser: [],
      drawHeatmap: true,
      drawGazePoints: false,
      drawMotionTrail: false,
      motionTrailLength: 10,
      motionTrailRadius: 10,
      resizeChangestamp: 0,
      advancedSettings: false
    }
  },
  computed: {
    env: function(){
      return process.env.VUE_APP_ENV
    },
    videoWidth(){
      return Math.round(this.baseWidth * (1 + this.scale/10))
    },
    videoHeight(){
      return Math.round(this.baseHeight * (1 + this.scale/10))
    },
    videoSource(){
      return this.item?.heatmapWebVid ? `${this.contentRoot}${this.item.heatmapWebVid}` : null
      // return this.videoURL // ?? ( this.env == 'local' ? "BC_LR_01.mp4" :"https://s3.eu-central-1.amazonaws.com/dev.public.avris.io/balint-test1/BC_LR_01.mp4" )
    },
    sections(){
      return this.item?.sections?.map(s => ({
        ...s, 
        width: Math.round((s.to - s.from)/this.videoDuration*100),
        left: Math.round(s.from/this.videoDuration*100)
      }))
    },
    markers(){
      return this.item?.markers?.map(m => ({
        ...m, 
        left: Math.round(m.at/this.videoDuration*100)
      }))
    },
    metadataKeys(){
      return Array.from(new Set(this.recordings.flatMap(r => Object.keys(r.metadata)))).filter(m => !m.startsWith('cp_'))
    },
    // areasPerUser(){
    //   return this.recordings.map(rec => ({
    //     userId: rec.userId,
    //     metadata: rec.metadata,
    //     areas: this.areas.map((a,index2) => ({
    //       label: a.label,
    //       temp00: index2,
    //       temp000: rec.areaSamples[index2],
    //       temp0: rec,
    //       temp1: rec.areaSamples ? rec.areaSamples[index2] : 0,
    //       temp2: rec.x.length,
    //       coverage: ((rec.areaSamples ? rec.areaSamples[index2] : 0)/rec.x.length*100)
    //     }))
    //   }))  
    // }
    selectedRecordings(){
      return this.recordings.filter(r => r.checked)
    }
  },
  methods: {
    getAreaLabelClass(area){
      if(area.y > 28)
        return 'top'
      else if((area.y + area.h + 28) < this.videoHeight)
        return 'bottom'
      else
        return 'inside'
    },
    saveSettings(){
      localStorage.setItem('heatmapsettings', JSON.stringify({
        sampleRate: this.sampleRate,
        slidingWindowDataPoints: this.slidingWindowDataPoints,
        drawGazePoints: this.drawGazePoints,
        drawMotionTrail: this.drawMotionTrail,
        drawHeatmap: this.drawHeatmap,
        motionTrailLength: this.motionTrailLength,
        motionTrailRadius: this.motionTrailRadius,
        dataMin: this.dataMin,
        dataMax: this.dataMax,
        radius: this.radius
      }))
    },
    loadSettings(){
      let settings = localStorage.getItem('heatmapsettings')
      if(settings){
        settings = JSON.parse(settings)
        this.sampleRate = settings.sampleRate ?? 10
        this.slidingWindowDataPoints = settings.slidingWindowDataPoints ?? 10
        this.drawGazePoints = settings.drawGazePoints ?? false
        this.drawMotionTrail = settings.drawMotionTrail ?? false
        this.drawHeatmap = settings.drawHeatmap ?? true
        this.motionTrailLength = settings.motionTrailLength ?? 10
        this.motionTrailRadius = settings.motionTrailRadius ?? 10
        this.dataMin = settings.dataMin ?? 0
        this.dataMax = settings.dataMax ?? 10
        this.radius = settings.radius ?? 10
      }
    },
    resetSettings(){
      this.sampleRate = 10
      this.slidingWindowDataPoints = 10
      this.drawGazePoints = false
      this.drawMotionTrail = false
      this.drawHeatmap = true
      this.motionTrailLength = 10
      this.motionTrailRadius = 10
      this.dataMin = 0
      this.dataMax = 10
      this.radius = 10
      this.saveSettings()
    },
    allSelectedChanged(ev){
      this.recordingList.forEach(r => r.checked = ev.srcElement.checked) // this.$set(r, 'checked', ev.srcElement.checked))
    },    
    videoMetadataLoaded(){
      this.videoDuration = this.$refs.video?.duration     
      let aspectRatio =  this.$refs.video?.videoWidth / this.$refs.video?.videoHeight
      this.baseHeight = Math.round(this.baseWidth / aspectRatio)
      if(this.sections)
          this.$refs.sections.style.width = (this.$refs.mediatimerange.offsetWidth - 18) + 'px'
    },
    areasToClipboard(exportToFile){
      let csv = "Label,X,Y,Width,Height,Current coverage %,Overall coverage %"
      this.areas.forEach(area => {
        csv += `\n${area.label},${area.xN},${area.yN},${area.wN},${area.hN},${area.liveCoverage ?? 0},${area.aggregatedCoverage ?? 0}`
      })
      if(exportToFile){
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `session-${this.sessionId}-segment-${this.segmentId}-item-${this.item.id}-areas-export.csv`);
      }
      else{
        navigator.clipboard.writeText(csv)
        this.$refs.snackbar.show('Areas copied to clipboard.')
      }
    },
    areasJsonToClipboard(){
      navigator.clipboard.writeText(JSON.stringify(this.areas))
      this.$refs.snackbar.show('Areas JSON copied to clipboard.')
    },
    areasPerUserToClipboard(exportToFile){
      // headers
      let csv = ""
      this.metadataKeys.forEach(key => {
        csv += `${key},`
      })
      csv += 'UserId'
      this.areas.forEach(area => {
        csv += `,${area.label}`
      })

      // overall coverage
      csv += '\n'
      this.metadataKeys.forEach(() => {
        csv += `,`
      })
      csv += 'Overall'
      this.areas.forEach(area => {
        csv += `,${area.aggregatedCoverage.toFixed(2)}`
      })

      // per user breakdown
      this.areasPerUser.forEach(item => {
        csv += '\n'
        this.metadataKeys.forEach(key => {
          csv += `${item.metadata[key]},`
        })
        csv += item.userId
        item.areas.forEach(a => {
          csv += `,${a.coverage.toFixed(2)}`
        })
      })

      if(exportToFile){
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `session-${this.sessionId}-segment-${this.segmentId}-item-${this.item.id}-areas-per-user-export.csv`);
      }
      else{
        navigator.clipboard.writeText(csv)
        this.$refs.snackbar.show('Areas per user copied to clipboard.')
      }
    },
    exportRawDataCsv(exportToFile){
      let maxSamples = _.max(this.recordings.map(r => r.x.length))      
      let sampleRate = this.recordings[0].sampleRate

      // headers
      let csv = 'UserId'
      this.metadataKeys.forEach(key => {
        csv += `,${key}`
      })
      for (let i = 0; i < maxSamples; i++) {
        csv += `,${i/sampleRate}`        
      }

      // data
      this.recordings.forEach(rec => {
        csv += `\n${rec.userId}`

        this.metadataKeys.forEach(key => {
          csv += `,${rec.metadata[key]}`
        })

        for (let i = 0; i < maxSamples; i++) {
          csv += `,${rec.x[i]}_${rec.y[i]}`        
        }
      })

      if(exportToFile){
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `session-${this.sessionId}-segment-${this.segmentId}-item-${this.item.id}-head-movement-raw-data-export.csv`);
      }
      else {
        navigator.clipboard.writeText(csv)
        this.$refs.snackbar.show('Raw recordings with metadata copied to clipboard.')
      }

    },
    recordingToClipboard(rec){
      // headers
      let csv = ""
      // Object.keys(rec.metadata).forEach(key => {
      //   csv += `${key},`
      // })
      csv += `Calculated Time (s) from ${rec.sampleRate}/s sample rate,X,Y`

      // data
      for (let index = 0; index < Math.min(rec.x.length, rec.y.length); index++) {        
        csv += '\n'
        // if(rec.metadata?.length)
        //   rec.metadata.forEach(meta => {
        //     csv += `${meta},`          
        //   })
        csv += `${(1/rec.sampleRate*index).toFixed(2)},${rec.x[index]},${rec.y[index]}`
      }

      navigator.clipboard.writeText(csv)
      this.$refs.snackbar.show('Raw gaze data of user copied to clipboard.')
    },
    getAreaColor(rank, total){   
      const f = chroma.scale([ '#28BEB9', '#83bc38', '#e2a24e', '#d97550', '#f95151', '#e244ee', '#a14eff', '#2070f5', '#19b271', '#c9ce44'])      
      return f(rank/total).toString()
    },
    addArea(){      
      this.areas.push({
        w: 70,
        h: 90,        
        x: 0,
        y: 0,
        wN: 70/this.videoWidth,
        hN: 90/this.videoHeight,
        xN: 0,
        yN: 0,
        label: `label${this.areas.length + 1}`,
        liveCoverage: 0,
        aggregatedCoverage: 0,    
        color: '#000',
        checked: true    
      })
      this.areas.forEach((area,index) => area.color = this.getAreaColor(index, this.areas.length))
      this.saveAreas()
    },
    removeArea(index){
      this.areas.splice(index, 1)
      this.saveAreas()
    },
    async loadAreas(){
      this.loading = true
      this.$nprogress.start()      
      let url = `analytics/metadata?sessionId=${this.sessionId}&segmentId=${this.segmentId}&itemId=${this.item.id}`      
      let resp = await axios({ url: url, method: 'GET' }) 
      try{
        this.areas = JSON.parse(resp.data.map(d => d.areasJson));     
        this.scaleAreas()
      }
      catch{
        this.areas = []
      }
      this.$refs.snackbar.show(`${this.areas.length} areas loaded from server.`)  
      this.loading = false
      this.$nprogress.done()
    },
    async saveAreas(){
      this.loading = true
      this.$nprogress.start()
      let url = `analytics/metadata?sessionId=${this.sessionId}&segmentId=${this.segmentId}&itemId=${this.item.id}`    
      let data = JSON.stringify(this.areas)  
      // eslint-disable-next-line 
      await axios.post(url, data
      , {
        headers: {
            'Content-Type': 'application/json',
        }
      }
      )   
      this.$refs.snackbar.show('Areas saved on server.')    
      this.loading = false
      this.$nprogress.done()
    },
    recalcArea(){
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      let area = this.areas[areaindex]
      area.liveCoverage = slidingWindowDataPoints.filter(d => 
        d.xN > area.xN && d.xN < (area.xN + area.wN) && d.yN > area.yN && d.yN < (area.yN + area.hN)
      ).length / slidingWindowDataPoints.length * 100
      area.liveCoverage = (!area.liveCoverage || isNaN(area.liveCoverage)) ? 0 : area.liveCoverage
      this.aggregateAreaCoverage()
      this.saveAreas()
    },
    scaleAreas(){
      // redraw areas for new player size      
      this.areas = this.areas.map(a => {
        a.x = a.xN * this.videoWidth
        a.y = a.yN * this.videoHeight
        a.w = a.wN * this.videoWidth
        a.h = a.hN * this.videoHeight
        return a
      })      
      this.resizeChangestamp++   // key changing "technique" to force remounting a component. reason: vdr only sets parent bounds at mount and on window resize event
    },
    onAreaResize: function (x, y, width, height) {
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      //console.log(areaindex)
      this.areas[areaindex].x = x
      this.areas[areaindex].y = y
      this.areas[areaindex].w = width
      this.areas[areaindex].h = height
      this.areas[areaindex].wN = width/localThis.videoWidth,
      this.areas[areaindex].hN = height/localThis.videoHeight,
      this.areas[areaindex].xN = x/localThis.videoWidth,
      this.areas[areaindex].yN = y/localThis.videoHeight
    },
    onAreaDrag: function (x, y) {
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      this.areas[areaindex].x = x
      this.areas[areaindex].y = y
      this.areas[areaindex].xN = x/localThis.videoWidth,
      this.areas[areaindex].yN = y/localThis.videoHeight   
    },
    async loadRecordings(download){
      this.loading = true
      this.$nprogress.start()      

      let url = `sessions/${this.sessionId}/recordings?segment=${this.segmentId}&item=${this.item.id}`      
      // let query
      // switch (this.serial) {
      //   case "-1":
      //     query = ''
      //     break;
      //   case null:
      //     query = '&archived=false'
      //     break;      
      //   default:
      //     query = `&serial=${this.serial}`
      //     break;
      // }   
      // url += query
      if(download)
        url += '&presign=true'
      let resp = await axios({ url: url, method: 'GET' }) 

      if(download){
        this.recordings.length = 0
        let promises = resp.data.map(rec => axios.get(rec.blobUrl, {transformRequest: (data, headers) => {
            delete headers.common['Authorization'];
            return data;
          }})
        )

        await Promise.all(promises) 
          .then((data) => {
            data.forEach((rec, i) => {           
              resp.data[i].x = rec.data.x
              resp.data[i].y = rec.data.y   
              resp.data[i].checked = true        
              this.recordings.push(resp.data[i])
            });
          })
          .catch((err) => {
            console.log(err)
          })
        }

        this.recordingList = resp.data

        this.loading = false
        this.$nprogress.done()
    },
    seekRecordings(/*ev*/){
      // use ev.target.currentTime or this.$refs.video.currentTime
      // reset heatmap to current sample when seek happens      
      slidingWindowDataPoints.length = 0
      // render current sample if video paused (for example if prof is just clicking around and examining individual video frames)
      if(this.$refs.video.paused)
        this.renderSamples()
    },
    playRecordings(){
      sampleInterval = Math.round(1000/this.sampleRate)
      this.replayInterval = setInterval(this.renderSamples, sampleInterval)
    },
    pauseRecordings(){
      clearInterval(this.replayInterval)
    },
    renderSamples(){ 
      sample = Math.round(this.$refs.video?.currentTime*1000/sampleInterval)
      //console.log(this.$refs.video.currentTime + ' ' + sample + ' ' + sampleInterval)

      // no samples available 
      if(!sample || sample >= failsafe || lastSample == sample){     
        //console.log('skipping duplicate sample render' )          
        return
      } 
      // avoid rendering same sample twice (edge-case if intervals would overlap for whatever reason)
      lastSample = sample
      
      // current samples
      let dataPoints = this.selectedRecordings.map(r =>
        ({
          xN: r.x[sample],
          yN: r.y[sample],
          x: Math.round(r.x[sample]*this.videoWidth),
          y: Math.round(r.y[sample]*this.videoHeight),
          value: 1,
          radius: this.radius
        })
      )

      // maintain samples in sliding window
      if(this.slidingWindow){
        slidingWindowDataPoints.push(...dataPoints)        
        // push out "old" samples from window
        // TODO use Queue.js if performance will be an issue, although heatmap.js uses arrays anyway
        // TODO2 here we assume all recordings have the same amount of samples, and we calculate how many samples that means in total in our window.
        // but this could act weird at the end of videos if not all users have equal number of samples. probably minor thing, but worth keeping note of this
        if (slidingWindowDataPoints.length > this.slidingWindowSamples*this.selectedRecordings.length) {              
          slidingWindowDataPoints.splice(0, this.selectedRecordings.length)
        }

        // render heatmap
        this.heatmapInstance.setData({
          max: this.dataMax,
          min: this.dataMin,
          data: slidingWindowDataPoints
        })

        // live area coverage calculations
        this.areas.forEach(area => {
          area.liveCoverage = slidingWindowDataPoints.filter(d => 
            d.xN > area.xN && d.xN < (area.xN + area.wN) && d.yN > area.yN && d.yN < (area.yN + area.hN)
          ).length / slidingWindowDataPoints.length * 100
        });

        // gaze and motion trail
        let c = this.$refs.motionTrailCanvas
        var ctx = c.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);

        if(this.drawMotionTrail){
          motionPositions.push({
            dataPoints
          })        
          //get rid of first item
          if (motionPositions.length > this.motionTrailLength) {
            motionPositions.shift()
          }

          //console.log(motionPositions)
          for (var i = 0; i < motionPositions.length; i++) {
            let ratio = (i + 1) / motionPositions.length;
            for (let j = 0; j < motionPositions[i].dataPoints.length; j++) {
              ctx.beginPath()
              ctx.arc(motionPositions[i].dataPoints[j].x, motionPositions[i].dataPoints[j].y, this.motionTrailRadius*ratio, 0, 2 * Math.PI, true)
              ctx.fillStyle = "rgba(204, 102, 153, " + ratio / 2 + ")"
              ctx.fill()
            }
          }
        }

        if(this.drawGazePoints){
          dataPoints.forEach(p => {
            ctx.fillStyle = "rgb(255, 0, 0)"
            ctx.fillRect(p.x-2, p.y-2, 5, 5)
          });
        }
      }
    },
    resize(){      
      this.baseWidth = Math.floor((document.querySelector('.dashboard .analytics .data').clientWidth - 42) / 2)
      this.baseHeight = Math.floor(this.baseWidth / 2)

      setTimeout(() => {
      this.$refs.heatmap.innerHTML = ''
      this.heatmapInstance = h337.create({
        // only container is required, the rest will be defaults
        container: document.querySelector('.heatmap'),
      })
      }, 300)
      this.scaleAreas()
    },
    debug(){
      //this.$refs.video.pause()
      // console.log(slidingWindowDataPoints)
      // console.log(this.heatmapInstance.getData())
      // //this.heatmapInstance.repaint()
      
      // let wtf = this.heatmapInstance.getData()
      // console.log(wtf.data[0])
      // wtf.data[0].value = 5

      // this.heatmapInstance.setData({
      //   max: this.dataMax,
      //   min: this.dataMin,
      //   data: //[wtf.data[0]]
      //   [{
      //     x: 293.07,
      //     y: 147.12,
      //     value: 5
      //   }]
      // })
      // console.log(this.heatmapInstance.getData())

      // var nuConfig = {
      //   radius: this.radius,
      //   maxOpacity: 1,
      //   minOpacity: 0,
      //   blur: .85
      // }
      // this.heatmapInstance.configure(nuConfig)

      // console.log(this.radius)
      // console.log(this.heatmapInstance.getData())
    },
    aggregateAreaCoverage(){
      // init
      let totalSamples = 0
      this.areas.forEach(area => {
        area.coveredSampleCount = 0
      })
      // calc avg for each area, how many samples were in total inside the area
      this.recordings.forEach(rec => {
        rec.areaSamples = Array(this.areas.length).fill(0)
        
        for (let i = 0; i < rec.x.length; i++) {          
          this.areas.forEach((area,index) => {
            if(rec.x[i] > area.xN && rec.x[i] < (area.xN + area.wN) && rec.y[i] > area.yN && rec.y[i] < (area.yN + area.hN)){
              area.coveredSampleCount++
              rec.areaSamples[index]++
            }
          })
          totalSamples++
        }   
      })

      this.areas.forEach(area => {
        area.aggregatedCoverage = area.coveredSampleCount / totalSamples * 100
      })

      this.areasPerUser = this.recordings.map(rec => ({
        userId: rec.userId,
        metadata: rec.metadata,
        areas: this.areas.map((a,index2) => ({
          label: a.label,          
          coverage: ((rec.areaSamples ? rec.areaSamples[index2] : 0)/rec.x.length*100)
        }))
      }))  
    },
    async setScale(){
      this.heatmapInstance.setDataMax(this.dataMax)
      this.heatmapInstance.setDataMin(this.dataMin)
      //console.log("max: " + this.dataMax + " min: " + this.dataMin)
    }
  },
  created() {
    this.debouncedResize = _.debounce(function() {
      localThis.resize()
    }, 300) 
    this.loadSettings()
  },
  async mounted(){
    this.resize()
    
    localThis = this

    this.$refs.video.onseeked = this.seekRecordings
    this.$refs.video.onplay = this.playRecordings
    this.$refs.video.onpause = this.pauseRecordings

    //await this.downloadRecordings()
    //this.addArea()   
    window.addEventListener("resize", this.debouncedResize );
  },
  async beforeDestroy(){
    window.removeEventListener("resize", this.debouncedResize, false);
  },
  watch: {  
    segmentId: function(){
      this.item = undefined // when switching between segments or conditions, we want to reset so we don't show old stuff      
    },  
    item: async function(){
      this.$refs.video.pause()
      //this.$refs.video.setAttribute('src', this.videoSource)
      //console.log(this.$refs.video)
      await nextTick() // at this stage the video element is re-rendered and virtual DOM updated -> we can re-attach required event handlers
      this.$refs.video.onseeked = this.seekRecordings
      this.$refs.video.onplay = this.playRecordings
      this.$refs.video.onpause = this.pauseRecordings

      this.recordings = []
      this.recordingList = []
      this.areas = []
      
      this.$refs.heatmap.innerHTML = ''
      if(this.item){              
        await this.loadRecordings(true)
        this.heatmapInstance = h337.create({
          container: document.querySelector('.heatmap')
        })
        await this.loadAreas()
        await this.aggregateAreaCoverage()
      }   
    },
    selectedRecordings(){
      this.allSelected = this.selectedRecordings?.length == this.recordings?.length
    },
    scale: function(){
      //this.scaleAreas() 
      setTimeout(() => {        
        this.$refs.heatmap.innerHTML = ''
        this.heatmapInstance = h337.create({
          container: document.querySelector('.heatmap'),
        })
        window.dispatchEvent(new Event('resize'))
        if(this.sections)
          this.$refs.sections.style.width = (this.$refs.mediatimerange.offsetWidth - 18) + 'px'  

        setTimeout(() => this.scaleAreas(), 300)

      }, 300)  // workaround for vdr components to update parent bounds + heatmap instance recalculate canvas bounds (both libraries automatically self-adjust during init, but not on resize)
    }
  },
}
</script>



<style lang="scss">

.heatmap2{

  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  gap: 20px;
  max-width: 100%;
  //overflow: hidden;
  height: 100%;

  .videolist{
    height: 20px;
    div{      
      margin-right: 16px;   
      float: left;   
      cursor: pointer;

      &:hover{
        color: $text-green1;
      }

      .index{
        display: inline-block;
        padding-left:8px;
        font-size: 12px;
        line-height: 20px;
        width: 30px;
        height: 20px;
        background: url(../assets/clip.svg) no-repeat left;
        background-size: 30px 20px;
      }

      .title{
        padding-left: 8px;
        max-width: 100px;
        text-overflow: ellipsis;
        display: inline-block;
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: middle;
      }

      &.selected{
        .index{
          background: url(../assets/clip-full.svg) no-repeat left;
          color: white;
        }
      }
    }
  }

  .right{
    display: flex;
    flex-direction: column;
    position: relative;

    button.advanced{
      width: 16px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 0;

      img{
        width: 16px;
        height: 16px;
      }
    }
  }

  .settings{
    min-height:70px;
    input, span{
      vertical-align: middle;
    }    

    &.advanced{
      flex: 1 1 auto;
      height: 0px;
      overflow-y: auto;
    }
  }

  .arealist{
    flex: 1 1 auto;
    height: 0px;
    overflow-y: auto;

    .color{
      width:20px; 
      height:20px; 
      border-radius: 2px; 
      border: solid 1px #bababa;
    }

    .chart{
      width:100%;
      position: relative;
      background-color: #e1e9ef;
      height: 28px;
      container: barchart / size;

      .current, .overall{
        width: 100%;
        position: absolute;
        left: 0;
        transform-origin: left;
        height: 28px;
      }

      .current {        
        width: 3px;
        height: 28px;
        background-color: #0640a1;        
      }

      .overall {
        background-color: #58b7db;
      }
    }
  }

  .buttons{
    height: 40px;
    margin-top: 20px;
    background-color: white;
    button{
      width:20px;
      height: 20px;
      min-width: 20px;
      margin: 0 8px;
    }
  }

  .video-placeholder{
    width: 100%;
    height: 50%;
    background: url('../assets/clip-grey.svg') center no-repeat #f9f9f9;
  }

  .heatmapcontainer{
    position: relative;
    // min-width: 600px;  
    // min-height: 344px;
    width: 100%;
    margin-top: 10px;

    .heatmap{
      width: 100%;
      // min-width: 600px;
      // min-height: 300px;
      //border: 1px solid greenyellow;
      position: absolute !important;
      top: 0px;
      left: 0px;
      z-index: 10;

      canvas{
        width: 100%;
        height: 100%;
      }
    }

    #motionTrailCanvas{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 11;
    }

    video{
      //position: absolute;
      //top: 0px;
      //left: 0px;
      // width: 600px;
      height: auto;
    }

    .sections{
      width: 364px;
      margin-left: 55px;
      position: relative;
      height: 40px;

      .section {
        border: 4px solid white;
        border-top: 4px solid $green1;
        font-size: 12px;
        position: absolute;
      }

      .marker{
        position: absolute;
        border-radius: 50px;
        width: 12px;
        height: 12px;
        overflow: visible;
        background-color: $green2;
        border: 1px dotted white;
        top: -4px;
        cursor: pointer;
        transform: translateX(-50%);

        &:hover{
          span{
            display: inline;
          }
        }

        span{
          display: none;
          position: absolute;
          white-space: nowrap;
          top: -25px;
          background-color: $green2;
          color: white;
          font-size: 12px;
          padding: 5px;
          transform: translate(-45%);
        }
      }
    }

    .mediacontroller{
      // position: absolute;
      top: 0px;
      //min-width: 600px;  
      width: 100%;
      //min-height: 344px;
      left: 0px;

      transition: all 0.3s ease;  
    }

    media-controller {
      --media-icon-color: #00684b;
      --media-control-background: white;
      --media-control-hover-background: white;
      --media-range-bar-color: #00684b;
      --media-range-track-background: #e4ecf1;
      --media-range-thumb-background: #00684b;
    }

    media-play-button:hover, media-mute-button:hover, media-time-range:hover, media-seek-backward-button:hover, media-seek-forward-button:hover{
      --media-icon-color: #86c596;
      --media-range-thumb-background: #86c596;
    }

    media-time-range{
      --media-range-track-height: 8px;
      --media-range-track-border-radius: 4px;
      --media-range-thumb-width: 16px;
      --media-range-thumb-height: 16px;
    }

    media-time-display{
      color: black;
    }

    media-seek-backward-button, media-seek-forward-button{
      transform: scale(1.3);
    }

    media-play-button{
      border: 3px solid #00684b;
      border-radius: 50px;
      background: white;
      transform: scale(0.8);
      padding: 7px;

      --media-button-icon-transform: scale(1.4);

      &:hover{
        border: 3px solid #86c596;
      }
    }

    media-control-bar{
      opacity:1;
      background-color: white;
      padding-top: 16px;
    }

    .areas{
      // min-width: 600px;
      // min-height: 300px;
      position: absolute !important;
      top: 0px;
      left: 0px;
      z-index: 20;

      .vdr{
        // border: 1px dashed greenyellow;
        border: dashed 2px #fff; // bad design again as this is not visible on bright videos, but whatever..
        border-radius: 4px;

        span.label{
          display: inline-block;
          border: solid 1px #fff;
          height: 24px;
          //color: white;
          padding: 0 2px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color:black;
          //transform: translateY(-28px);
          opacity: 0.8;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          
          &.top{
            top: -28px;
          }
          &.bottom{
            bottom: -28px;
          }
        }
      }
    }
  }

  

}

</style>
