<template>
  <div class="results2" id="hmmmmm">     
    <!-- <button @click="exportcanvas">Export results as image to clipboard</button> -->


    <!-- <div v-for="question in filteredQuestions" :key="question.id" :questionId="question.id" class="question" :class="[question.type, {ranking: question.ranking}]" :id="'imgexport'+question.id">      
      <div class="title" @click="toggle">
        {{stripHtml(question.Qdashboard ? question.Qdashboard : question.Q)}}
        <div class="totalcount"><span :class="{zero: answerCount(question) == 0}">{{answerCount(question)}}</span> Answers</div>
      </div>          
      <div class="answers">   -->


    <div class="question shopping2" v-for="shop in filteredShopResults" :key="shop.id">      
      <div class="title" @click="toggle">
        {{stripHtml(shop.shopName)}}  
        <div class="totalcount"><span :class="{zero: answerCount(shop) == 0}">{{answerCount(shop)}}</span> Answers</div>     
      </div>  
      <!-- <div class="subtitle">Distribution of items selected for each decision point in the segment</div>         -->
      <div class="answers">  
        <div>
          <div style="font-size:14px; font-weight:bold; padding: 10px 0;">Most selected items</div>
          <table>
            <tr>
              <th style="text-align:left;">Item</th>
              <th style="text-align:center; padding: 0 15px;">Price</th>
              <th style="text-align:left; width: 50%;">Frequency of selected items</th>
            </tr>
            <tr v-for="option in shop.mergedPurchasables" :key="option.optionId" class="option" :class="{hasImage: option.image}">
              <td class="label">              
                <div>
                  <img v-if="option.image" :src="contentRoot + option.image" width="48" height="48" />
                  <span>{{stripHtml(option.name)}}</span>
                </div>
              </td>
              <td style="text-align: center;">
                {{shop.currencyIcon ? shop.currencyIcon : ''}}{{option.cost}}
              </td>
              <td class="chart">
                <div class="bar" style="display:block;">
                  <div class="progress" v-bind:style="{ width: option.percentage+'%' }"></div>
                  <div class="value">{{option.count}}</div>
                </div>              
              </td>
            </tr>
          </table>
        </div>
        <div style="display: flex; flex-direction: column;">
          <div style="font-size:14px; font-weight:bold; padding: 10px 0;">Average budget spent</div>
          <div class="barline" style="flex-grow: 1; flex-basis: 200px;">
              <canvas :id="`barlinechart_${shop.id}`" :shopId="shop.id"></canvas>
          </div>
          <div style="color:#777; font-size:14px; text-align:center;">Percentile groups per budget spent</div>
          <div style="color:#777; font-size:14px; text-align:center; padding: 10px 0 20px 0;">
            <span style="display: inline-block; width:16px; height:2px; background-color: #93d0e7; margin-bottom:5px;"></span><span style="padding: 0 16px 0 8px;">Budget stance</span>
            <span style="display: inline-block; width:16px; height:2px; background-color: #753fe0; margin-bottom:5px;"></span><span style="padding: 0 16px 0 8px;">No. of items</span>
          </div>
        </div>
      </div>
    </div>    

    <Snackbar ref="snackbar" /> 

  </div>
</template>



<script>

//import chroma from 'chroma-js'
import * as htmlToImage from 'html-to-image'
import Snackbar from '@/components/Snackbar.vue'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import _ from 'lodash'
import {quantile} from 'simple-statistics'

export default {
  name: 'ShoppingRetail',
  components: {
    Snackbar
  },
  props: {    
    shops: Array,
    results: Array,
    contentRoot: String,
    segmentId: Number    
  },
  data: function() {
    return {   
      tempVueBugHack: 'whatever'
    };
  },
  computed: {
    filteredShopResults(){
      if(!this.tempVueBugHack)
        return []
      let tmp = this.shops?.filter(q => q.shoppingGroup && q.shopInit && q.segmentId == this.segmentId)

      tmp.forEach(shop => {
        shop.mergedPurchasables = shop.purchasables ? [...shop.purchasables] : []        
        this.shops.filter(s => s.segmentId == this.segmentId && s.id != shop.id && s.shoppingGroup == shop.shoppingGroup).forEach(sibling => {          
          sibling.purchasables.forEach(p => {
            shop.mergedPurchasables.push(p)
          })
        })
      })

      tmp.forEach(shop => {
        let res = this.res(shop.id)
        shop.mergedPurchasables.forEach(purchasable => {
          purchasable.count = res.filter(r => r.optionId == purchasable.optionId).length
          let p = purchasable.count/res.length*100
          purchasable.percentage = p ? (Math.round(p * 100) / 100) : 0
        })
        shop.mergedPurchasables = _.orderBy(shop.mergedPurchasables, 'count', 'desc')
      })
      return tmp
    }    
  },  
  methods: {
    async exportcanvas(){
      let item
      await htmlToImage.toBlob(document.getElementById('hmmmmm'), {cacheBust: true, backgroundColor: "white", pixelRatio: 3})
      .then(function (blob) {        
        item = new window.ClipboardItem({ "image/png": blob })           
      })
      await navigator.clipboard.write([item])
      this.$refs.snackbar.show('Image copied to clipboard')
    },  
    toggle(ev){      
      ev.target.classList.toggle('collapsed');
    },    
    stripHtml(html){
      return html?.replace(/(<([^>]+)>)/gi, "")
    }, 
    answerCount(question){     
        return new Set(this.res(question.id).map(r => r.userId))?.size ?? 0 
    },
    res(questionId, optionId){
      return this.results.filter(r => 
        r.questionId == questionId 
        && (!optionId || (r.optionId == optionId) || (!r.optionId && (r.value == optionId)) /* for ratings mapping to 1,2,3,4,5 */)   
        &&  r.segmentId == this.segmentId)     
    },
    resPercentage(questionId, optionId){      
      let p = this.res(questionId, optionId).length/this.res(questionId).length*100
      return p ? (Math.round(p * 100) / 100)  : 0     
    },
    setAnimationMaxHeight(){
      document.querySelectorAll('.question .answers').forEach(el => {
        el.style.maxHeight = el.clientHeight + 'px';
      })
    },
    initBarLineCharts(){
      document.querySelectorAll('.barline canvas').forEach(canvas => {  
        let chart = Chart.getChart(canvas.getAttribute('id'))        
        
        let shop = this.filteredShopResults.find(q => q.id == canvas.getAttribute('shopId'))   
        if(!shop)
          return;

        let shopResults = []

        let qres = this.res(shop.id)
        if(qres.length > 0){
          // 1. group by user, SUM cost of items, SUM number of items bought
          let perUser = _(qres).groupBy('userId').map((value, key) => ({userId: key, /*answers: value,*/ count: value.length, spent: _.sum(value.map(v => shop.mergedPurchasables.find(p => p.optionId == v.optionId)?.cost)) })).value()
          // 2. order by budget spent
          let ordered = _.sortBy(perUser, ['spent'])    
          // 3. map them to 5 "percentile" groups and calc averages (with small data sets there will be overlaps)          
          //    3.1 find the "percentiles"
          //    3.2 calc avg spending for each "percentile group"
          //    3.3 calc avg number of items bought for each "percentile group"

          let values = ordered.map(o => o.spent)
          for (let i = 0; i < 5; i++) {
            let from = quantile(values, i == 0 ? 0 : (i*0.2).toFixed(1))
            let to = quantile(values, i == 4 ? 1 : ((i+1)*0.2).toFixed(1))
            
            if(this.$store.state.avrisdebug)
              console.log((i*0.2).toFixed(1) + '|' + from + '_' + ((i+1)*0.2).toFixed(1) + '|' + to)

            let set = ordered.filter(r => ((i == 0 && r.spent >= from) || (i > 0 && r.spent > from)) && r.spent <= to)       
            let as = _.meanBy(set, 'spent') 
            let ac = _.meanBy(set, 'count') 
            shopResults.push({
              avgSum: isNaN(as) ? 0 : as,
              avgCount: isNaN(ac) ? 0 : ac
            })            
          }

          if(this.$store.state.avrisdebug)
            console.log(shopResults)
        }        
        else{ 
          for (let i = 0; i < 5; i++) {
            shopResults.push({
              avgSum: 0,
              avgCount: 0
            })            
          }
          //shopResults = [
            // {
            //   avgSum: 50.83,
            //   avgCount: 1
            // },
            // {
            //   avgSum: 70.97,
            //   avgCount: 2
            // },
            // {
            //   avgSum: 132.24,
            //   avgCount: 3
            // },
            // {
            //   avgSum: 150.1,
            //   avgCount: 3
            // },
            // {
            //   avgSum: 216.96,
            //   avgCount: 1
            // },
          //]
        }


        if(!chart){
          chart = new Chart(
            canvas,
            {
              type: 'bar',              
              data: {
                labels: ['0-20','21-40','41-60','61-80','81-100'],
                datasets: [
                  {
                    data: shopResults.map(t => t.avgSum),
                    backgroundColor: '#93d0e7',
                    order: 1,
                    maxBarThickness: 28,
                    datalabels: {
                      anchor: 'end',
                      align: 'top',
                      color: '#000'
                    }  
                  },
                  {
                    data: shopResults.map(t => t.avgCount),
                    borderColor: '#753fe0',
                    type: 'line',
                    yAxisID: 'y1',
                    order: 0,
                    pointRadius: 0,
                    borderWidth: 2,
                    datalabels: {
                      display: false
                    }                    
                  }
                ]
              },
              plugins: [ChartDataLabels],
              options:{
                layout: {
                    padding: {
                        top: 25
                    }
                },
                scales: {
                  x: {
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    title: {
                      display: true,
                      text: 'Budget stance',
                      color: '#777',
                      font: {
                        size: 14
                      } 
                    },
                    grid: {
                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                      drawTicks: false,
                      //drawBorder: false
                      borderWidth: 10,
                      borderColor: '#fff'
                    },
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    title: {
                      display: true,
                      text: 'No. of items',
                      color: '#777',
                      font: {
                        size: 14
                      } 
                    },
                    min: 0,
                    max: shop.mergedPurchasables.length,   
                    grid: {                      
                      drawTicks: false,
                      //drawBorder: false
                      borderWidth: 10,
                      borderColor: '#fff',
                      color: '#f2f5f7'
                    },              
                  }
                },
                maintainAspectRatio: false,
                responsive: true,                
                plugins: {
                  legend: {
                    display: false
                  }                         
                }              
              }
            }
          );
        }
        else{
          // update chart datasets          
          chart.data.datasets[0].data = shopResults.map(t => t.avgSum)
          chart.data.datasets[1].data = shopResults.map(t => t.avgCount)
          chart.options.scales.y1.max = shop.mergedPurchasables.length
          chart.update()  
        }          
      });
    }
  },
  watch: {
    shops: function(){
      setTimeout(() => this.initBarLineCharts(), 500)
    },
    results: function(){
      this.initBarLineCharts()
    }
  },
  created() {
  },
  mounted(){       
    this.initBarLineCharts()
  }  
}
</script>



<style lang="scss">

.results2{
  position: relative;
  clear: both;

  .question{
    margin: 0 20px 0 0;
    border-bottom: $bordermixin;
        
    .title{      
      padding: 17px 100px 17px 40px;
      color: $december;
      font-size: 16px;
      font-weight: bold;
      padding-right: 100px;
      cursor: pointer;
      position: relative;

      &:after{
        content: '\276E';
        position: absolute;
        font-size: 18px;
        left: 10px;
        top: 30%;
        color: #00684b;
        opacity: 0.5;
        transform: rotate(90deg);
        transition: transform 0.5s ease;      
      }

      &:hover:after{
        opacity: 1;
      }

      &.collapsed{
        overflow: hidden;
        & + .answers{
          max-height: 0px !important;
          padding-bottom: 0px;
          padding-top: 0px;
          overflow: hidden;               
          transform-origin: top;  
          transform: scaleY(0); 
          margin-top: 0;
          margin-bottom: 0;
        }

        &:after{
          transform: rotate(270deg);
        }
      }
    }

    .answers{
      margin: 0px 40px 0 40px;
      transition: all 0.5s ease;
      transform: scaleY(1); 
    }

    &.shopping2{

      .title{
        padding-bottom: 10px;
      }

      .subtitle{
        font-size:14px;
        color: #777;
        padding: 0 0 20px 40px;
      }

      .answers{
        display: grid;
        gap: 40px;
        grid-template-columns: 60% 40%;
        
        table .option td{
          border: none;
        }

        .chart{
          min-width: unset;
        }
      }
      
    }

  }
}
   
.analytics .shopping2 .answers .barline {
  max-height: 200px;
}   
.analytics.expanded .shopping2 .answers .barline {
  max-height: 300px;
} 

@media (min-height: 900px) {        
  .analytics .shopping2 .answers .barline {
    max-height: 250px;
  }   
  .analytics.expanded .shopping2 .answers .barline {
    max-height: 350px;
  } 
}


</style>
