<template>
  <div class="results2" id="hmmmm">     
    <!-- <button @click="exportcanvas">Export results as image to clipboard</button> -->
    <div class="question shopping">      
      <div class="title" @click="toggle">
        Shopping Cart - Budget        
      </div>  
      <div class="subtitle">Distribution of items selected for each decision point in the segment</div>        
      <div class="answers">  
        <div class="shops">
          <div v-for="shop in filteredShops" :key="`piechart_${shop.id}`" class="shop">
            <div class="shopinfo">
              <div class="name">
                {{stripHtml(shop.shopName)}}
                <span>({{answerCount(shop)}} answers)</span>
              </div>
              <div class="multi" v-if="shop.multiChoice">Multiple selections per learner</div>
            </div>
            <div class="piechart">
              <canvas :id="`piechart_${shop.id}`" :shopId="shop.id"></canvas>
              <div></div>
            </div>
            <div class="legend">
              <div v-for="(p,idx) in shop.purchasables" :key="shop.id+p.optionId">
                <span class="dot" :style="{backgroundColor: getPieColor(idx, shop.purchasables.length-1)}"></span>
                {{stripHtml(p.name)}} <b>{{p.cost > 0 ? ` - ${shop.currencyIcon ? shop.currencyIcon : ''}${p.cost}` : ''}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    

    <Snackbar ref="snackbar" /> 

  </div>
</template>



<script>

import chroma from 'chroma-js'
import * as htmlToImage from 'html-to-image'
import Snackbar from '@/components/Snackbar.vue'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  name: 'Shopping',
  components: {
    Snackbar
  },
  props: {    
    shops: Array,
    results: Array,
    contentRoot: String,
    segmentId: Number    
  },
  data: function() {
    return {   
      tempVueBugHack: 'whatever'
    };
  },
  computed: {
    filteredShops(){
      if(!this.tempVueBugHack)
        return []
      let tmp = this.shops?.filter(q => !q.shoppingGroup && q.segmentId == this.segmentId)
      tmp.forEach(shop =>{        
        if(shop.allowNotInterested){
          if(!shop.purchasables)
            shop.purchasables = []
          if(!shop.purchasables.find(x => x.optionId == 0))
            shop.purchasables.push({
              optionId: 0,
              name: "Not interested"
            })
        }
      })
      return tmp
    }    
  },  
  methods: {
    async exportcanvas(){
      let item
      await htmlToImage.toBlob(document.getElementById('hmmmm'), {cacheBust: true, backgroundColor: "white", pixelRatio: 3})
      .then(function (blob) {        
        item = new window.ClipboardItem({ "image/png": blob })           
      })
      await navigator.clipboard.write([item])
      this.$refs.snackbar.show('Image copied to clipboard')
    },    
    getPieColor(rank, total){      
      const f = chroma.scale([ '#93d0e7', '#3e9fbc', '#063f5b', '#f37e09', '#fbab19'])      
      return f(rank/total).toString()
    },
    toggle(ev){      
      ev.target.classList.toggle('collapsed');
    },    
    stripHtml(html){
      return html?.replace(/(<([^>]+)>)/gi, "")
    }, 
    answerCount(question){     
        return new Set(this.res(question.id).map(r => r.userId))?.size ?? 0 
    },
    res(questionId, optionId){
      return this.results.filter(r => 
        r.questionId == questionId 
        && (!optionId || (r.optionId == optionId) || (!r.optionId && (r.value == optionId)) /* for ratings mapping to 1,2,3,4,5 */)   
        &&  r.segmentId == this.segmentId)     
    },
    setAnimationMaxHeight(){
      document.querySelectorAll('.question .answers').forEach(el => {
        el.style.maxHeight = el.clientHeight + 'px';
      })
    },
    initPieCharts(){
      document.querySelectorAll('.piechart canvas').forEach(canvas => {  
        let chart = Chart.getChart(canvas.getAttribute('id'))        
        
        let shop = this.shops.find(q => q.id == canvas.getAttribute('shopId'))   
        if(!shop)
          return;

        let qres = this.res(shop.id)

        let shopResults = shop.purchasables.map((p, idx) => ({
            name: p.name,
            cost: p.cost,
            color: this.getPieColor(idx, shop.purchasables.length-1),
            count: qres.filter(r => r.optionId == p.optionId).length          
        }))

        var empty = !shopResults.find(r => r.count > 0)
        if(empty)
          canvas.classList.add('empty')
        else
          canvas.classList.remove('empty')

        if(!chart){
          chart = new Chart(
            canvas,
            {
              type: 'doughnut',              
              data: {
                labels: shopResults.map(t => `${t.name} ${t.cost >= 0 ? ((shop.currencyIcon ?? '') + t.cost) : ''}`),
                datasets: [
                  {
                    data: shopResults.map(t => t.count),
                    backgroundColor: shopResults.map(t => t.color),
                    pointStyle: 'circle'
                  }
                ]
              },
              plugins: [ChartDataLabels],
              options:{
                maintainAspectRatio: false,
                radius: 80,
                plugins: {
                  legend: {
                    display: false
                  },
                  datalabels: {
                    formatter: (value/*, ctx*/) => {
                        return value > 0 ? value : null;
                    },
                    color: function(/*ctx*/) {
                        return '#fff'; //ctx.dataset.data.find(x => x > 0) ? '#000' : '#fff'
                    }
                  }           
                }              
              }
            }
          );
        }
        else{
          chart.data.datasets[0].data = shopResults.map(t => t.count)
          chart.data.datasets[0].backgroundColor = shopResults.map(t => t.color)
          chart.data.labels = shopResults.map(t => `${t.name} ${t.cost >= 0 ? ((shop.currencyIcon ?? '') + t.cost) : ''}`)
          chart.update()  
        }
          
      });
    }
  },
  watch: {
    shops: function(){
      setTimeout(() => this.initPieCharts(), 500)
    },
    results: function(){
      this.initPieCharts()
    }
  },
  created() {
  },
  mounted(){       
    this.initPieCharts()
  }  
}
</script>



<style lang="scss">

.results2{
  position: relative;
  clear: both;

  .question{
    margin: 0 20px 0 0;
    border-bottom: $bordermixin;
        
    .title{      
      padding: 17px 100px 17px 40px;
      color: $december;
      font-size: 16px;
      font-weight: bold;
      padding-right: 100px;
      cursor: pointer;
      position: relative;

      &:after{
        content: '\276E';
        position: absolute;
        font-size: 18px;
        left: 10px;
        top: 30%;
        color: #00684b;
        opacity: 0.5;
        transform: rotate(90deg);
        transition: transform 0.5s ease;      
      }

      &:hover:after{
        opacity: 1;
      }

      &.collapsed{
        overflow: hidden;
        & + .subtitle + .answers{
          max-height: 0px !important;
          padding-bottom: 0px;
          padding-top: 0px;
          overflow: hidden;               
          transform-origin: top;  
          transform: scaleY(0); 
          margin-top: 0;
          margin-bottom: 0;
        }

        &:after{
          transform: rotate(270deg);
        }
      }
    }

    .answers{
      margin: 0px 40px 0 40px;
      transition: all 0.5s ease;
      transform: scaleY(1); 
    }

    &.shopping{

      .title{
        padding-bottom: 10px;
      }

      .subtitle{
        font-size:14px;
        color: #777;
        padding: 0 0 20px 40px;
      }

      .shops{
        gap: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        padding-bottom: 20px;

        .shop{
          display: grid;
          grid-template-columns: 160px 1fr;
          //grid-template-rows: minmax(45px, 1fr);
          grid-auto-rows: auto;
        }

        .shopinfo{          
          grid-column: 1 / 3;
          grid-row: 1 / 2;
          font-size: 16px;
          margin-bottom: 15px;

          .multi{
            font-size: 14px;
            color: #777;
            padding-top:3px;            
          }

          span{
            font-size: 10px;
            color: #777;
            white-space: nowrap;
          }
        }

        .piechart{
          position: relative;
          min-height: 160px;
          max-height: 160px;
          max-width: 160px;
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          align-self: center;

          canvas{ 
            max-height: 160px;
            max-width: 160px;
          }

          canvas ~ div{
            background: radial-gradient(circle, transparent 40%, rgb(237, 237, 237) 40%);
            border-radius: 100%;
            height: 160px;
            width: 160px;
            margin: 0px;
            position: absolute;
            left:0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
          canvas.empty ~ div{
            display: block;
          }
        }

        .legend{
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          gap: 5px;

          div{
            position: relative;
            padding-left: 16px;
            .dot{
              border-radius: 20px;
              width: 12px;
              height: 12px;
              position: absolute;
              left: 0px;
              top:2px;
            }
          }
        }
      }
    }

  }
}

</style>
